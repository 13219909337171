<template>
  <header class="ya-te-header border-b border-gray-300">
    <div class="co-container-lg navbar h-full py-0 pl-4 pr-0 lg:px-4">
      <div
        class="flex w-full flex-row items-center justify-between lg:mr-auto lg:w-auto"
        style="height: 70px"
      >
        <a
          class="ya-te-navbar-brand h-full"
          href="https://ly-academy.yahoo.co.jp/tech/"
        >
          <img
            class="te-top-cover-image-title w-full"
            src="~/assets/images/YaTe/YaTeCommon/Logo/logo_ly.svg"
            alt="LINEヤフーテックアカデミー"
            loading="lazy"
          >
        </a>
      </div>
    </div>
  </header>
</template>

<style scoped>
@import url('~/assets/css/YaTe/YaTeCommon/TheYaTeCommonHeader.css');
</style>
