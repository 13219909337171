<template>
  <div>
    <TheYaTeCommonHeader />
    <TheYaTeCommonNote class="bg-co-gray-100" />
    <main class="bg-co-gray-100">
      <slot />
    </main>
    <TheYaTeCommonFooterSimple />
  </div>
</template>

<script setup lang="ts">
useHead({
  htmlAttrs: {
    "data-theme": 'techacademy',
  },
})
</script>

<script lang="ts">
import { defineNuxtComponent } from '#app'
import TheYaTeCommonHeader from '~/components/YaTe/YaTeCommon/Header/TheYaTeCommonHeader.vue'
import TheYaTeCommonNote from '~/components/YaTe/YaTeCommon/TheYaTeCommonNote.vue'
import TheYaTeCommonFooterSimple from '~/components/YaTe/YaTeCommon/Footer/TheYaTeCommonFooterSimple.vue'

const options = {
  components: {
    TheYaTeCommonHeader,
    TheYaTeCommonNote,
    TheYaTeCommonFooterSimple,
  },
  head: {
    link: [
      {
        hid: 'favicon',
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon-yate.ico',
      },
    ],
  },
}

export default defineNuxtComponent(options)
</script>


